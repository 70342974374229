<template>
	<div>
		<base-data-table
			translate
			:title="$t('complaints')"
			:actions="actions"
			:headers="headers"
			:provider="$complaints"
			:fetch-function="getComplaints"
			@view="onViewComplaint"
			@remove="onRemoveComplaint"
			:add="$currentUser.role === 'admin' ? false : true"
			@click:add="onCreateComplaint"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{
					$complaints.perPage * ($complaints.currentPage - 1) + index + 1
				}}
			</template>

			<template v-slot:[`item.createdAt`]="{ item }">
				<div>
					{{ $m(item.createdAt).format('Do MMM YYYY') }}
				</div>
			</template>

			<template v-slot:[`item.status`]="{ item }">
				<BaseStatusChanger
					v-if="$currentUser.isAdmin"
					:height="32"
					:translate="false"
					:value="item.status"
					:items="ComplaintStatuses"
					:on-change="v => onChangeStatus(item, v)"
				/>
				<BaseStatus v-else :status="item.status" />
			</template>
		</base-data-table>

		<v-dialog
			v-model="complaintForm.dialog"
			persistent
			max-width="1198"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<ComplaintForm
				:onView="complaintForm.onView"
				:complaint="complaintForm.data"
				@onClose="onResetComplaintForm"
			/>
		</v-dialog>
	</div>
</template>

<script>
import { ComplaintStatuses } from '@/consts'
import { mapGetters, mapActions } from 'vuex'

import ComplaintForm from '../components/forms/complaintForm.vue'

export default {
	name: 'Complaints',
	components: {
		ComplaintForm,
	},
	data() {
		return {
			ComplaintStatuses,
			complaintForm: {
				data: null,
				onView: false,
				dialog: false,
				timeout: null,
			},
			headers: [
				{ text: 'headers.ns', value: 'serial' },
				{ text: 'headers.complaintNumber', value: 'complaintNumber' },
				{ text: 'headers.complaintType', value: 'categories.0.name' },
				{ text: 'headers.applicant', value: 'applicant.name' },
				// { text: 'headers.applicantType', value: 'applicantType' },
				{ text: 'headers.submissionDate', value: 'createdAt' },
				{ text: 'headers.status', value: 'status' },
				{ text: 'headers.actions', value: 'actions' },
			],
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('complaints', ['$complaints']),
		actions() {
			return [
				{
					icon: 'mdi-eye-outline',
					event: 'view',
					text: this.$t('view-details'),
				},
				this.$currentUser.isAdmin && {
					icon: 'mdi-trash-can',
					color: 'error',
					event: 'remove',
					text: this.$t('actions.delete'),
				},
			].filter(Boolean)
		},
	},
	methods: {
		...mapActions('complaints', [
			'getComplaints',
			'deleteComplaint',
			'changeStatusOfComplaint',
		]),
		onResetComplaintForm() {
			this.complaintForm.dialog = false
			clearTimeout(this.complaintForm.timeout)

			this.complaintForm.timeout = setTimeout(() => {
				this.complaintForm.data = null
				this.complaintForm.onView = false
			}, 500)
		},
		onRemoveComplaint(event) {
			this.$root.confirmation = {
				title: this.$t('delete-confirmation'),
				message: this.$t('sure-to-delete-complaint'),
				handler: async () => {
					await this.deleteComplaint(event.id)
				},
			}
		},
		onChangeStatus(item, status) {
			return this.changeStatusOfComplaint({
				status,
				id: item.id,
			})
		},
		onViewComplaint(complaint) {
			clearTimeout(this.complaintForm.timeout)
			this.complaintForm = {
				dialog: true,
				onView: true,
				timeout: null,
				data: complaint,
			}
		},
		onCreateComplaint() {
			clearTimeout(this.complaintForm.timeout)
			this.complaintForm = {
				data: null,
				dialog: true,
				onView: false,
				timeout: null,
			}
		},
	},
}
</script>
