<template>
	<v-form
		:readonly="onView"
		ref="form"
		lazy-validation
		@submit.prevent="handleCreateComplaint"
	>
		<v-card
			v-if="onView"
			class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
		>
			<div
				class="tw-flex tw-justify-between tw-items-start tw-text-black tw-mb-6 lg:tw-mb-12"
			>
				<div class="">
					<h2 class="tw-text-22 tw-font-semibold">
						{{ $t('complaint-details') }}
					</h2>
					<span class="tw-block tw-opacity-50 tw-leading-tight">
						{{ $t('headers.complaintNumber') }}{{ $t('colon') }}
						{{ complaint.complaintNumber }}
					</span>
				</div>
				<div class="tw-flex tw-space-s-3">
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>
			</div>
			<div>
				<div class="tw-grid tw-grid-cols-6 tw-gap-4 lg:tw-gap-6">
					<div>
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47 tw-text-sm"
						>
							<span class="tw-font-normal tw-text-sm">
								{{ $t('headers.complaintType') }}
							</span>
						</div>

						<div class="tw-font-bold tw-text-base">
							<!-- {{ complaint.applicantType }} -->
							{{ complaint.categories[0].name }}
						</div>
					</div>
					<div>
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							<span class="tw-font-normal tw-text-sm">
								{{ $t('applicant-name') }}
							</span>
						</div>

						<div class="tw-font-bold tw-text-base">
							{{ complaint.applicant.name }}
						</div>
					</div>
					<div>
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							<span class="tw-font-normal tw-text-sm">
								{{ $t('applicant-phone') }}
							</span>
						</div>
						<div class="tw-font-bold tw-text-base">
							{{ complaint.applicant.phone }}
						</div>
					</div>
					<div class="tw-col-span-2">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							<span class="tw-font-normal tw-text-sm">
								{{ $t('applicant-email') }}
							</span>
						</div>
						<div>
							<div class="tw-font-bold tw-text-base">
								{{ complaint.applicant.email }}
							</div>
						</div>
					</div>
					<div>
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							<span class="tw-font-normal tw-text-sm">{{
								$t('headers.status')
							}}</span>
						</div>
						<div class="tw-font-bold tw-text-base">
							{{ complaint.status }}
						</div>
					</div>
				</div>
				<div class="tw-mt-7">
					<span class="tw-font-normal tw-text-sm">
						{{ $t('headers.description') }}
					</span>
					<div class="tw-font-bold tw-font-base tw-mt-4">
						{{ complaint.description }}
					</div>
				</div>
				<div
					class="tw-mt-7"
					v-if="complaint && complaint.attachments.length"
				>
					<div class="tw-mb-4">
						<span class="tw-font-normal tw-text-sm">
							{{ $t('attachments') }}
						</span>
					</div>
					<div
						class="tw-grid tw-gap-4"
						:class="[
							complaint.attachments.length <= 4
								? `tw-grid-cols-${complaint.attachments.length}`
								: 'tw-grid-cols-4',
						]"
					>
						<a
							v-for="attach in complaint.attachments"
							:key="attach.id"
							:href="$image(attach.attachedLink)"
							target="_blank"
							class="tw-h-full"
						>
							<v-card
								outlined
								class="tw-h-60 tw-relative tw-flex tw-justify-center tw-items-center"
							>
								<v-icon
									v-if="isPDF(attach.attachmentName)"
									size="96"
									color="red"
								>
									<!-- mdi-file-pdf -->
									mdi-file-pdf-box
								</v-icon>
								<v-img
									v-else
									cover
									class="tw-w-full tw-h-full"
									:alt="attach.attachmentName"
									:src="$image(attach.attachedLink)"
								/>
							</v-card>
						</a>
					</div>
				</div>
			</div>
			<div class="tw-mt-7">
				<span class="tw-font-normal tw-text-sm">
					{{ $t('submission-data') }}
				</span>
				<div class="tw-mt-4">
					<div class="tw-font-bold tw-font-base tw-mb-4">
						{{ $m(complaint.createdAt).format('DD-MM-YYYY HH MM a') }}
					</div>
				</div>
			</div>

			<v-card-actions
				v-if="!onView"
				class="tw-mt-10 lg:tw-mt-14 tw-flex tw-p-0"
			>
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetForm"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>{{ $t('actions.cancel') }}</span
					>
				</v-btn>
				<v-btn
					type="submit"
					height="58"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					depressed
					color="primary"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>$t('actions.submit')</span
					>
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-card
			v-else
			class="tw-overflow-x-hidden tw-overflow-y-auto tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6"
		>
			<h2 class="tw-text-22 tw-font-semibold lg:tw-mb-14 tw-mb-6">
				{{ $t('complaintInfo') }}
			</h2>
			<v-row>
				<v-col cols="12" lg="6">
					<div
						class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47 tw-text-sm"
					>
						<span class="tw-font-normal tw-text-sm">
							{{ $t('headers.complaintType') }}
						</span>
					</div>
					<v-autocomplete
						v-model="mComplaint.categoryId"
						:loading="isLoadingCategories"
						:items="$complaintCategories.data"
						:error-messages="errors.categoryId"
						:rules="[rules.required($t('headers.complaintType'))]"
						:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
						:placeholder="$t('headers.complaintType')"
						flat
						dense
						outlined
						return-object
						item-value="id"
						item-text="name"
						class="user-input"
						hide-details="auto"
						@input="errors.categoryId = []"
					/>
				</v-col>
				<v-col cols="12" lg="6">
					<!-- <template v-if="$currentUser.isAdmin">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							<span class="tw-font-normal tw-text-sm">
								{{ $t('applicantType') }}
							</span>
						</div>
						<v-select
							v-model="mComplaint.applicantType"
							:items="appicantTypes"
							:rules="[rules.required()]"
							:placeholder="$t('applicantType')"
							:error-messages="errors.applicantType"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							flat
							dense
							outlined
							class="user-input"
							hide-details="auto"
							item-value="value"
							item-text="label"
							@input="errors.applicantType = []"
						/>
					</template> -->
				</v-col>
				<v-col cols="12">
					<div
						class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
					>
						<span class="tw-font-normal tw-text-sm">
							{{ $t('headers.description') }}
						</span>
					</div>
					<v-textarea
						v-model="mComplaint.description"
						:rows="8"
						:placeholder="$t('headers.description')"
						:rules="[rules.required($t('headers.description'))]"
						:error-messages="errors.description"
						outlined
						auto-grow
						class="user-input"
						background-color="white"
						@input="errors.description = []"
					/>
				</v-col>
				<v-col cols="12">
					<BaseUpload
						v-model="mComplaint.attachments"
						:title="$t('adminContracts.UploadCardCopy')"
						:subtitle="$t('max500Image')"
						:rules="[rules.required($t('attachments'))]"
						:error-messages="errors.attachments"
						multiple
						accept=".png,.jpg.jpeg,.pdf"
						@input="errors.attachments = []"
					/>
				</v-col>
			</v-row>
			<v-card-actions
				v-if="!onView"
				class="tw-mt-10 lg:tw-mt-14 tw-flex tw-p-0"
			>
				<v-btn
					text
					outlined
					height="58"
					color="primary"
					class="tw-flex-1 tw-rounded-lg"
					@click="onResetForm"
				>
					<span class="tw-text-lg tw-font-medium tw-capitalize tw-text-72">
						{{ $t('actions.cancel') }}
					</span>
				</v-btn>
				<v-btn
					:loading="isLoading"
					depressed
					height="58"
					type="submit"
					color="primary"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
					>
						{{ $t('actions.submit') }}
					</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { toFormData } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'

export default {
	name: 'ComplaintForm',
	mixins: [
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	props: {
		complaint: Object,
		onView: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			errors: {},
			isLoading: false,
			isLoadingCategories: false,
			mComplaint: {
				status: 'new',
				categoryId: null,
				// applicantType: '',
				description: '',
				attachments: [],
			},
		}
	},
	created() {
		if (this.$complaintCategories.data.length) return

		this.isLoadingCategories = true
		this.getComplaintCategories().then(() => {
			this.isLoadingCategories = false
		})
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('category', ['$complaintCategories']),
		// appicantTypes() {
		// 	return [
		// 		{ value: 'item', label: this.$t('item') },
		// 		{ value: 'customer', label: this.$t('customer') },
		// 	]
		// },
	},
	methods: {
		...mapActions('complaints', ['createComplaint']),
		...mapActions('category', ['getComplaintCategories']),
		onResetForm() {
			this.$refs.form.reset()
			this.$emit('onClose')
		},
		isPDF(filename = '') {
			return /\.(pdf)$/.test(filename)
		},
		async handleCreateComplaint() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			this.isLoading = true
			const [error] = await this.createComplaint(
				toFormData(
					{
						...this.mComplaint,
						categoryId: this.mComplaint.categoryId.id,
						categoryName: this.mComplaint.categoryId.name,
						attachments: this.mComplaint.attachments.map(attachment => {
							return attachment.file
						}),
					},
					{ convertCase: 'snake_case' }
				)
			)
			this.isLoading = false

			this.errors = error?.errors || {}
			if (error) {
				this.$utils.scrollIntoError(this)
				this.$toast.error(this.$t('error-creating', [this.$t('complaint')]))
				return
			}

			this.$toast.success(this.$t('complaint-created'))
			this.onResetForm()
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
